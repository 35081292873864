/**
 * @created by Zheko Hristov on 16/09/2021
 * @last-updated by Zheko Hristov on 30/08/2023
 *
 * Product filters page scripts
 **/

// import noUiSlider from 'nouislider/distribute/nouislider.js';

document.addEventListener('turbolinks:before-render', function () {
    (function () {
        'use strict';

    })();
});

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        // scroll to active item or open submenu and scroll to active submenu item
        productCategoriesScrollToActiveItem();

        // scroll to active item or open submenu and scroll to active submenu item function
        function productCategoriesScrollToActiveItem() {
            // scroll to active category list item
            $('.products__list--categories > li').each(function () {
                const thisCategoryListItem = $(this);
                const thisCategoryLink = thisCategoryListItem.children('a');

                if (thisCategoryLink.hasClass('active')) {
                    productCategoriesListScroll(thisCategoryListItem);

                    return false;
                }
            });

            // open the submenu if there is active item and scroll to him
            openSubmenuWithActiveItemAndScroll();
        }

        // open submenu if there is active item function
        function openSubmenuWithActiveItemAndScroll() {
            $('.products__list--subcategories a').each(function () {
                const thisSubcategoryLink = $(this);

                if (thisSubcategoryLink.hasClass('active')) {
                    const subcategoriesParentListItemContainer =
                        thisSubcategoryLink.closest('.item--has-subcategory');
                    const subcategoriesParentListContainer = thisSubcategoryLink.closest(
                        '.products__list--subcategories'
                    );
                    const subcategoriesParentListContainerFullHeight =
                        subcategoriesParentListContainer.prop('scrollHeight');

                    subcategoriesParentListItemContainer.addClass('item--submenu-showed');
                    subcategoriesParentListContainer.height(
                        subcategoriesParentListContainerFullHeight
                    );

                    productCategoriesListScroll(thisSubcategoryLink);

                    return false;
                }
            });
        }

        // scroll in container to active item function
        function productCategoriesListScroll(activeElement) {
            setTimeout(function () {
                $('.products__list--categories').animate(
                    {
                        // animate your right div
                        scrollTop: activeElement.position().top, // to the position of the target
                    },
                    400,
                    'linear'
                );
            }, 750);
        }

        // open/close subcategories containers
        $('.icon__container--show-subcategories').on('click', function (e) {
            e.preventDefault();

            const thisCategoryArrowContainer = $(this);
            const thisCategorySubcategoryList = thisCategoryArrowContainer
                .parent('a')
                .siblings('.products__list--subcategories');
            const thisCategoryListItemContainer =
                thisCategoryArrowContainer.parents('.item--has-subcategory');

            thisCategoryListItemContainer.toggleClass('item--submenu-showed');

            if (thisCategoryListItemContainer.hasClass('item--submenu-showed')) {
                thisCategorySubcategoryList.height(
                    thisCategorySubcategoryList.prop('scrollHeight')
                );
            } else {
                thisCategorySubcategoryList.height(0);
            }
        });

        // price range slider
        // if ($('.price-range__container--slider').length > 0) {
        //     const minPriceValue = parseInt(
        //         $('.price-range__container--slider').attr('data-min-price')
        //     );
        //     const maxPriceValue = parseInt(
        //         $('.price-range__container--slider').attr('data-max-price')
        //     );
        //     const lowerPriceValue = parseInt(
        //         $('.price-range__container--slider').attr('data-lower-price')
        //     );
        //     const higherPriceValue = parseInt(
        //         $('.price-range__container--slider').attr('data-higher-price')
        //     );

        //     const priceRangeslider = document.querySelector('.price-range__container--slider');

        //     // fix back button issue - duplicates price range sliders
        //     if ($('.noUi-target').length) {
        //         $('.price-range__container--slider .noUi-base:nth-of-type(1').remove();
        //     }

        //     noUiSlider.create(priceRangeslider, {
        //         start: [lowerPriceValue, higherPriceValue],
        //         connect: true,
        //         step: 1,
        //         range: {
        //             min: minPriceValue,
        //             max: maxPriceValue,
        //         },
        //     });

        //     priceRangeslider.noUiSlider.on('update', function (values) {
        //         // update visual lables
        //         $('.price__text--first-handle span').text(values[0]);
        //         $('.price__text--second-handle span').text(values[1]);

        //         // update input values
        //         $('.js-price-range-lower').val('');
        //         $('.js-price-range-higher').val('');
        //         if (
        //             minPriceValue.toFixed(2) !== values[0] ||
        //             maxPriceValue.toFixed(2) !== values[1]
        //         ) {
        //             $('.js-price-range-lower').val(values[0]);
        //             $('.js-price-range-higher').val(values[1]);
        //         }
        //     });
        // }

        const estatePriceToInput =  $('.js-estate-price-to');

        if (estatePriceToInput.length > 0) {

            let priceChangeTimeout;
            const estateMinPrice = parseFloat(estatePriceToInput.attr('min'));
            const estateMaxPrice = parseFloat(estatePriceToInput.attr('max'));

            $('.price__text--first-handle span').text(estateMinPrice);
            $('.price__text--second-handle span').text(estateMaxPrice);

            estatePriceToInput.on('input', function () {
                const thisInput = $(this);
                
                clearTimeout(priceChangeTimeout);
                priceChangeTimeout = setTimeout(function () {                   
                    let priceToValue = parseFloat(thisInput.val());
                    if (isNaN(priceToValue)) {
                        priceToValue = estateMinPrice;
                    } else {
                        if (priceToValue > estateMaxPrice) {
                            // 30/08/2023 - for now comment the validation if the entered price is higher
                            // priceToValue = estateMaxPrice;
                        } else if (priceToValue < estateMinPrice){
                            priceToValue = estateMinPrice;
                        }
                    }
    
                    thisInput.val(priceToValue);
                    $('.js-price-range-higher').val(priceToValue);
                    $('.price__text--second-handle span').text(priceToValue);
                }, 250);
            });
        }

        // show/hide filters container on mobile
        $('.show-filters__container--mobile-icon').on('click', function () {
            const thisFilterIconContainer = $(this);
            thisFilterIconContainer.toggleClass('clicked');
            if (thisFilterIconContainer.hasClass('clicked')) {
                $('.filter-products__container--filters').addClass('showed');
                $('.products__list--categories').scrollTop(0);
                productCategoriesScrollToActiveItem();
            } else {
                $('.filter-products__container--filters').removeClass('showed');
            }
        });

        // submit filter form on sort select change
        $('.js-products-sort-by').on('change', function () {
            const thisSortOptionValue = $(this).val();
            $('.js-sort-by-option').val(thisSortOptionValue);
            $('.js-product-filter-form').trigger('submit');
        });

        // submit filter form on brand checkbox change with little delay to perform the animation
        // $('.js-brand-filter').on('change', function () {
        //     setTimeout(function () {
        //         $('.js-product-filter-form').trigger('submit');
        //     }, 200);
        // });


        // assumes there are only 2 options
        $('.js-transaction-type-filter').on('change', function () {
            const checkedOptions = $('.js-transaction-type-filter:checkbox:checked');
            let url = '';
            if(checkedOptions.length == 1){
                url = checkedOptions[0].dataset['url'];

            }else{
                url = $(this).data('all-url');
            }

            Turbolinks.visit(url);
        });

        // resize event
        let windowWidth = $(window).width();

        $(window).on('resize', function () {
            // fix issue with showing/hiding address bar on mobile devices on scroll is firing the resize event
            if (windowWidth !== $(window).width()) {
                setTimeout(function () {
                    // close subcategory list
                    // fix issue with recualculating the height of the container
                    $('.item--has-subcategory').each(function () {
                        const thisItem = $(this);
                        if (thisItem.hasClass('item--submenu-showed')) {
                            thisItem.find('.icon__container--show-subcategories').trigger('click');
                        }
                    });
                }, 100);

                windowWidth = $(window).width();
            }
        });
    })();
});
