var formScripts = (function($) {
    'use strict';
  
    function init() {
      bindContactFormProtection();
    }

    function bindContactFormProtection(){
      $('.js-contact-color-select').val('green');
    }
  
    return {
      init: init
    };
  
  })(jQuery);
  
export default formScripts;