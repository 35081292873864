import Alerts from './alerts-scripts';

var contactFormScripts = (function ($) {
    function init() {
        bindSubmit();
    }

    function bindSubmit() {
        const contactForm = $('#contact-form');
        const submitButton = $('#contact-form-submit');

        contactForm.on('submit', function (e) {
            e.preventDefault();

            submitButton.prop('disabled', true);

            // remove previous validation messages if any
            $('.field-validation-error').remove();
            $('#contact-form input, #contact-form select, #contact-form textarea').removeClass(
                'input-validation-error'
            );

            let formData = contactForm.serialize();
            const formAction = contactForm.attr('action');

            $.ajax({
                type: 'POST',
                url: formAction,
                data: formData, // serializes the form's elements.
            })
                .done(function (json) {
                    if (json.success) {
                        window.location = json.redirect_to;
                    } else {
                        submitButton.removeAttr('disabled');
                        let errors = json.errors;
                        let attrErrors = json.attr_errors;

                        Alerts.renderAlerts(errors, 'error');

                        Object.keys(attrErrors).forEach(function (key) {
                            showFieldError(key, attrErrors[key][0]);
                        });
                    }
                })
                .fail(function (xhr, status, error) {
                    console.log(xhr);
                    submitButton.removeAttr('disabled');

                    Alerts.renderAlert('Sorry, something went wrong', 'error');
                    // toastr.options.closeButton = true;
                    // toastr.error('Sorry, something went wrong');
                });
        });
    }

    function showFieldError(name, error) {
        let input = $('#contact_' + name);
        if (input.length > 0) {
            input.addClass('field--invalid');
            // input
            //     .parent()
            //     .append('<span class="field-validation-error text-danger">' + error + '</span>');
        }
    }

    return {
        init: init,
    };
})(jQuery);

export default contactFormScripts;
