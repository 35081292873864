import Alerts from './alerts-scripts';

/**
 * @created by Zheko Hristov on 27/08/2021
 *
 * Main scripts
 **/

document.addEventListener('turbolinks:before-render', function () {
    (function () {
        'use strict';

        /**
         * @created by Zheko Hristov on 03/09/2021
         * @last-updated by Zheko Hristov on 03/09/2021
         *
         * Hide page content before page renders to fix the propper opacity effect when changing pages
         **/

        $(
            '.header__container--top, .header__container--middle, .header__container--bottom, .main__container--outer, footer'
        ).removeClass('showed');

        /**
         * @created by Zheko Hristov on 08/09/2021
         * @last-updated by Zheko Hristov on 05/09/2023
         *
         * Remove 'fixed' class for propper display the header element container when changing pages with scroll
         **/

        $('.header__container--middle').removeClass('fixed');

        /**
         * @created by Zheko Hristov on 07/09/2021
         * @last-updated by Zheko Hristov on 10/11/2021
         *
         * Close the mobile menu if it is not closed after browser back button clicks
         **/

        if ($('.mobile-menu__container--icons .fa-times').hasClass('showed')) {
            $('.mobile-menu__container--icons .fa-times').removeClass('showed');
            $('.mobile-menu__container--icons .fa-bars').addClass('showed');
            $(
                '.header__container--bottom, .main__container--outer, footer, .button--scroll-top'
            ).removeClass('mobile-menu-showed');
        }
    })();
});

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        mainContainerMinHeight();

        // if the devices are iPad|iPhone|iPod, execute the function only for them,
        // because this function will stop the default zooming on Androd devices
        if (checkIfIsIOS()) {
            addMaximumScaleToMetaViewport();
        }

        /**
         * @created by Zheko Hristov on 31/08/2021
         * @last-updated by Zheko Hristov on 03/09/2021
         *
         * Show page content with some animation and delay
         **/

        setTimeout(function () {
            // remove loader container
            $('.loader__container--outer').removeClass('showed');
            $(
                '.header__container--top, .header__container--middle, .header__container--bottom, .main__container--outer, footer'
            ).addClass('showed');
        }, 250);

        /**
         * @created by Zheko Hristov on 23/09/2021
         * @last-updated by Zheko Hristov on 30/08/2023
         *
         * Product details page image change and zoom scripts
         **/

        const detailsProductFullImageContainer = $('.details-product__container--full-image');
        const detailsProductFullImageContainerInner = detailsProductFullImageContainer.find(
            '.product__container--image-inner'
        );
        const mainImageContainerOuter = $(
            '.details-product__container--main-image .product__container--image-outer'
        );
        // change main image
        const mainImageContainerInner = $(
            '.details-product__container--main-image .product__container--image-inner'
        );
        const secondaryImageContainer = $(
            '.details-product__container--secondary-images .product__container--image-outer'
        );

        // create array with image source values
        const secondaryImagesSources = [];
        secondaryImageContainer.each(function () {
            secondaryImagesSources.push($(this).attr('data-image-src'));
        });

        // open images gallery mode
        let secondaryActiveImageIndex;
        mainImageContainerOuter.on('click', function () {
            secondaryActiveImageIndex = $('.product__container--image-outer.active').index();
            updateDetailsProductFullscreenImage(secondaryActiveImageIndex);
            detailsProductFullImageContainer.addClass('showed');
        });

        // show previous image in images gallery mode
        detailsProductFullImageContainer.find('.fa-chevron-left').on('click', function () {
            if (secondaryImagesSources.length > 1) {
                if (secondaryActiveImageIndex === 0) {
                    secondaryActiveImageIndex = secondaryImagesSources.length - 1;
                } else {
                    secondaryActiveImageIndex--;
                }

                updateDetailsProductFullscreenImage(secondaryActiveImageIndex);
            }
        });

        // show next image in images gallery mode
        detailsProductFullImageContainer.find('.fa-chevron-right').on('click', function () {
            if (secondaryImagesSources.length > 1) {
                if (secondaryActiveImageIndex === secondaryImagesSources.length - 1) {
                    secondaryActiveImageIndex = 0;
                } else {
                    secondaryActiveImageIndex++;
                }

                updateDetailsProductFullscreenImage(secondaryActiveImageIndex);
            }
        });

        // show previous/next image in images gallery mode with the keyboard arrow keys
        $(document).on('keydown', function (event) {
            if (detailsProductFullImageContainer.hasClass('showed')) {
                switch (event.key) {
                    case 'ArrowLeft':
                        detailsProductFullImageContainer.find('.fa-chevron-left').trigger('click');
                        break;
                    case 'ArrowRight':
                        detailsProductFullImageContainer.find('.fa-chevron-right').trigger('click');
                        break;
                }
            }
        });

        // update full image mode background image function
        function updateDetailsProductFullscreenImage(index) {
            changeBackgroundImageWithAnimation(
                detailsProductFullImageContainerInner,
                secondaryImagesSources[index]
            );
        }

        // change background image with animation function
        function changeBackgroundImageWithAnimation(container, source) {
            container.css({
                'transform-origin': 'center',
                opacity: '0',
            });
            setTimeout(function () {
                container.css({
                    'background-image': 'url("' + source + '")',
                });
                setTimeout(function () {
                    container.css({ opacity: '1' });
                }, 100);
            }, 100);
        }

        // hide images gallery mode
        detailsProductFullImageContainer.find('.fa-times').on('click', function () {
            detailsProductFullImageContainer.removeClass('showed');
        });

        // update main background image container with image source of the active container
        $('.details-product__container--secondary-images').on(
            'click',
            '.product__container--image-outer',
            function () {
                const thisImageContainer = $(this);
                const thisImageSource = thisImageContainer.attr('data-image-src');

                secondaryImageContainer.removeClass('active');
                thisImageContainer.addClass('active');

                changeBackgroundImageWithAnimation(mainImageContainerInner, thisImageSource);
            }
        );

        // // touch start event
        // detailsProductFullImageContainerInner.on('touchstart', function () {
        //     // fix for touch screen issue with showing and hiding the address bar
        //     window.scrollTo(0, 0);
        // });

        // // reset styles on mouse leave
        // detailsProductFullImageContainerInner.on('mouseleave touchend', function () {
        //     $(this).css({ transform: 'scale(1)', 'transform-origin': 'center' });
        // });

        // // update styles on mouse move
        // detailsProductFullImageContainerInner.on('mousemove touchmove', function (event) {
        //     $(this).css({ transform: 'scale(1.5)' });
        //     let coodrinateX = 0;
        //     let coordinateY = 0;
        //     if (
        //         event.type == 'touchstart' ||
        //         event.type == 'touchmove' ||
        //         event.type == 'touchend' ||
        //         event.type == 'touchcancel'
        //     ) {
        //         let touch = event.originalEvent.touches[0] || event.originalEvent.changedTouches[0];
        //         coodrinateX = touch.pageX;
        //         coordinateY = touch.pageY;
        //     } else if (
        //         event.type == 'mousedown' ||
        //         event.type == 'mouseup' ||
        //         event.type == 'mousemove' ||
        //         event.type == 'mouseover' ||
        //         event.type == 'mouseout' ||
        //         event.type == 'mouseenter' ||
        //         event.type == 'mouseleave'
        //     ) {
        //         coodrinateX = event.clientX;
        //         coordinateY = event.clientY;
        //     }

        //     // This gives you the position of the image on the page
        //     var bbox = event.target.getBoundingClientRect();
        //     var mouseX = coodrinateX - bbox.left;
        //     var mouseY = coordinateY - bbox.top;

        //     // Then work out how far through the image as a percentage the mouse is
        //     var xPercent = (mouseX / bbox.width) * 100;
        //     var yPercent = (mouseY / bbox.height) * 100;

        //     // Then we change the `transform-origin` css property on the image to center the zoom effect on the mouse position
        //     //event.target.style.transformOrigin = xPercent + '% ' + yPercent + '%';
        //     // It's a bit clearer in jQuery:
        //     $(this).css('transform-origin', xPercent + '% ' + yPercent + '%');
        //     // We add the '%' units to make sure the string looks exactly like the css declaration it becomes.
        // });

        /**
         * @created by Zheko Hristov on 05/10/2021
         * @last-updated by Zheko Hristov on 05/10/2021
         *
         * Contacts page show details for active store
         **/

        $('.contacts-stores__container--inner').on(
            'click',
            '.contacts-stores__container--single',
            function () {
                const thisStoreContainer = $(this);
                const thisStoreIndex = thisStoreContainer.index();
                $('.contacts-stores__container--single').removeClass('active');
                thisStoreContainer.addClass('active');
                $('.contacts-stores__container-details-single').removeClass('active');
                $(
                    '.contacts-stores__container-details-single:nth-of-type(' +
                        (thisStoreIndex + 1) +
                        ')'
                ).addClass('active');
            }
        );

        /**
         * @created by Zheko Hristov on 12/10/2021
         * @last-updated by Zheko Hristov on 12/10/2021
         *
         * Open/close edit profile page sections
         **/

        // if there is some error for the form, open the specified edit profile container
        $('.edit-profile-page form').each(function () {
            const thisProfileForm = $(this);
            const formParentProfileSingleContainer = thisProfileForm.closest(
                '.profile__container--single'
            );
            const formParentProfileFormContainer = thisProfileForm.closest(
                '.profile-single__container--bottom'
            );

            if (thisProfileForm.hasClass('show-form')) {
                formParentProfileSingleContainer.addClass('showed');
                formParentProfileFormContainer.height(
                    formParentProfileFormContainer.prop('scrollHeight')
                );
            }
        });

        // show/hide specific profile edit container
        $('.edit-profile-page').on('click', '.profile-single__container--top', function () {
            const currentSingleSectionContainer = $(this).closest('.profile__container--single');
            const currentSingleSectionFormContainer = $(this).siblings(
                '.profile-single__container--bottom'
            );

            if (currentSingleSectionContainer.hasClass('showed')) {
                currentSingleSectionContainer.removeClass('showed');
                currentSingleSectionFormContainer.height(0);
            } else {
                let timeOutVal = 0;
                if ($('.edit-profile-page').find('.profile__container--single.showed').length) {
                    $('.edit-profile-page .profile__container--single').removeClass('showed');
                    $('.edit-profile-page .profile-single__container--bottom').height(0);
                    timeOutVal = 300;
                }

                setTimeout(function () {
                    currentSingleSectionContainer.addClass('showed');
                    currentSingleSectionFormContainer.height(
                        currentSingleSectionFormContainer.prop('scrollHeight')
                    );
                }, timeOutVal);
            }
        });

        /**
         * @created by Zheko Hristov on 31/08/2021
         * @last-updated by Zheko Hristov on 30/09/2021
         *
         * Header middle container add and remove class '.fixed'
         **/

        function headerMiddleContainerFixedOnOff() {
            if (window.innerWidth >= 801) {
                if (
                    $('header').height() - $('.header__container--bottom').innerHeight() <=
                    $(window).scrollTop()
                ) {
                    $('.header__container--middle').addClass('fixed');
                } else {
                    $('.header__container--middle').removeClass('fixed');
                }
            }
        }

        /**
         * @created by Zheko Hristov on 31/08/2021
         * @last-updated by Zheko Hristov on 10/11/2021
         *
         * Mobile menu scripts
         **/

        const openMobileMenuIcon = $('.mobile-menu__container--icons .fa-bars');
        const closeMobileMenuIcon = $('.mobile-menu__container--icons .fa-times');
        const mainMenuContainer = $('.header__container--bottom');
        const menuListItemsLength = mainMenuContainer.find('li').length;

        // open mobile menu
        openMobileMenuIcon.on('click', function () {
            mainMenuContainer.css({
                transition: 'transform 0.2s linear',
            });
            $(this).removeClass('showed');
            closeMobileMenuIcon.addClass('showed');
            mainMenuContainer.addClass('mobile-menu-showed');
            $('.main__container--outer, footer, .button--scroll-top').addClass(
                'mobile-menu-showed'
            );
            closeMobileMenuIcon.css({ 'pointer-events': 'none' });
            setTimeout(function () {
                closeMobileMenuIcon.css({ 'pointer-events': 'auto' });
                mainMenuContainer.css({
                    transition: 'none',
                });
            }, 200 * (menuListItemsLength + 1));
        });

        // close mobile menu
        closeMobileMenuIcon.on('click', function () {
            mainMenuContainer.css({
                transition: 'transform 0.2s linear ' + 0.2 * (menuListItemsLength + 1) + 's',
            });
            $(this).removeClass('showed');
            openMobileMenuIcon.addClass('showed');
            mainMenuContainer.removeClass('mobile-menu-showed');
            setTimeout(function () {
                mainMenuContainer.css({
                    transition: 'none',
                });
                $('.main__container--outer, footer, .button--scroll-top').removeClass(
                    'mobile-menu-showed'
                );
            }, 300 * (menuListItemsLength + 1));
            openMobileMenuIcon.css({ 'pointer-events': 'none' });
            setTimeout(function () {
                openMobileMenuIcon.css({ 'pointer-events': 'auto' });
            }, 200 * (menuListItemsLength + 1));
        });

        /**
         * @created by Zheko Hristov on 01/09/2021
         * @last-updated by Zheko Hristov on 02/09/2021
         *
         * iOS input zoom disable
         **/

        // function fo setting maximum-scale=1.0 to meta viewport
        // prevent iOS input zoom and not pevent the default zooming
        // revent Android default zooming
        function addMaximumScaleToMetaViewport() {
            const el = document.querySelector('meta[name=viewport]');
            if (el !== null) {
                let content = el.getAttribute('content');
                let re = /maximum\-scale=[0-9\.]+/g;
                if (re.test(content)) {
                    content = content.replace(re, 'maximum-scale=1.0');
                } else {
                    content = [content, 'maximum-scale=1.0'].join(', ');
                }
                el.setAttribute('content', content);
            }
        }

        // funcion for check the device
        function checkIfIsIOS() {
            return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        }

        /**
         * @created by Zheko Hristov on 02/09/2021
         * @last-updated by Zheko Hristov on 16/02/2021
         *
         * Main content minimum height
         **/

        function mainContainerMinHeight() {
            const windowHeight = $(window).height();
            const headerContainerHeight = $('header').height();
            const footerContainerHeight = $('footer').innerHeight();
            const calculatedMainContainerMinHeight =
                windowHeight - (headerContainerHeight + footerContainerHeight);

            $('.main__container--outer').css({
                'min-height':
                    calculatedMainContainerMinHeight > 0 ? calculatedMainContainerMinHeight : 0,
            });

            // calculate home slider height
            // const calculatedHomeSliderHeight = windowHeight - headerContainerHeight;
            // $('.home-slider__container--outer ').css({
            //     height:
            //         window.innerWidth > 1024
            //             ? calculatedHomeSliderHeight
            //             : calculatedHomeSliderHeight / 2,
            // });
        }

        /**
         * @created by Zheko Hristov on 29/10/2021
         * @last-updated by Zheko Hristov on 29/10/2021
         *
         * Toggle showing scroll to top button
         **/

        function showHideScrollTopButton() {
            if ($(window).scrollTop() > 0) {
                $('.button--scroll-top').addClass('showed');
            } else {
                $('.button--scroll-top').removeClass('showed');
            }
        }

        /**
         * @created by Zheko Hristov on 29/10/2021
         * @last-updated by Zheko Hristov on 29/10/2021
         *
         * Scroll to top
         **/

        $('.button--scroll-top').on('click', function () {
            $('body, html').animate(
                {
                    // animate your right div
                    scrollTop: 0,
                },
                400,
                'linear'
            );
        });

        /**
         * @created by Zheko Hristov on 08/02/2022
         * @last-updated by Zheko Hristov on 08/02/2022
         *
         * Click span and redirect to link
         **/

        // when click on phone number redirect to call - fix issue with nested  'a' elements
        $('.product__container--phone span').on('click', function (e) {
            e.preventDefault();
            window.location.href = $(this).attr('data-phone-number-link');
        });

        /**
         * @created by Zheko Hristov on 31/08/2021
         * @last-updated by Zheko Hristov on 04/09/2023
         *
         * Scroll event
         **/

        $(window).on('scroll', function () {
            headerMiddleContainerFixedOnOff();
            showHideScrollTopButton();
        });

        /**
         * @created by Zheko Hristov on 31/08/2021
         * @last-updated by Zheko Hristov on 04/09/2023
         *
         * Resize event
         **/

        let windowWidth = $(window).width();

        $(window).on('resize', function () {
            // fix issue with showing/hiding address bar on mobile devices on scroll is firing the resize event
            if (windowWidth !== $(window).width()) {
                setTimeout(function () {
                    headerMiddleContainerFixedOnOff();
                    mainContainerMinHeight();
                }, 100);
            }

            windowWidth = $(window).width();
        });

        /**
         * @created by Zheko Hristov on 24/09/2021
         * @last-updated by Zheko Hristov on 14/02/2022
         *
         * Fixes
         **/

        // Safari fix issue with contacts offices container window containers before and after pseudo elements
        if (
            navigator.userAgent.indexOf('Safari') != -1 &&
            navigator.userAgent.indexOf('Chrome') == -1
        ) {
            $('.building__container--floor span').addClass('safari-browser-fix');
        }
    })();
});
