// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

import 'owl.carousel';
import 'cookieconsent/build/cookieconsent.min';

import Cookies from './web/cookies';
import SocialMedia from './web/social-media';

import './web/main-scripts';
// import './web/home-top-slider-scripts';
import './web/product-filters-scripts';
import './web/form-validation-scripts';


import SliderScripts from './web/slider-scripts.js';
import AlertsScripts from './web/alerts-scripts';
import Locale from './web/locale';
import FormScripts from './web/form-scripts';
import ContactFormScripts from './web/contact-form-scripts';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// browser back button issue
document.addEventListener('turbolinks:before-render', function () {
    AlertsScripts.clearAlertsBrowserBack();
    // Locale.destroy();
    SliderScripts.destroy();
});

document.addEventListener('turbolinks:load', function () {
    SliderScripts.init();
    AlertsScripts.init();
    Cookies.init();
    // Locale.init();
    FormScripts.init();
    ContactFormScripts.init();
    SocialMedia.init();
});
